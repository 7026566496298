// imports
import React from "react"
import { graphql } from "gatsby"
import { withPrefix } from "gatsby"
import useScript from "../hooks/useScript"
import SEO from "../components/SEO"
import Navbar from "../components/Navbar"
import BackgroundDiv from "../components/BackgroundDiv"
import Img from "gatsby-image"


export default function ProjectPage({ data }) {
    useScript(withPrefix("scripts/jquery.min.js"));
    useScript(withPrefix("scripts/jquery.waypoints.min.js"));
    useScript(withPrefix("scripts/waypoint.js"));
    useScript("https://www.googletagmanager.com/gtag/js?id=UA-159994104-1");
    useScript(withPrefix("scripts/google-analytics.js"));

    const page = data.markdownRemark

    // logic to handle bitmap vs svg/gif images
    var outputImage
    if (page.frontmatter.pageImage.childImageSharp) {
        outputImage = <Img className="img img-2" fluid={page.frontmatter.pageImage.childImageSharp.fluid} />
    } else {
        console.log(page.frontmatter.pageImage.publicURL)
        outputImage = <img className="img img-2" src={page.frontmatter.pageImage.publicURL} alt="page"></img>
    }
    
    // logic to handle document links
    var output
    if (page.frontmatter.document) {
        var docPath
        var isUrl = /^http/.test(page.frontmatter.document.relativePath)
        if (isUrl) {
            docPath = page.frontmatter.document
        } else {
            docPath = withPrefix(page.frontmatter.document)
        }
        output = <p><a href={docPath}>Read the {page.frontmatter.documentType} <span className="ion-ios-arrow-forward"></span></a></p>
    } else {
        // backward compatibility
        output = <div dangerouslySetInnerHTML={{ __html: page.html }} />
    }

    // logic to handle content
    var content = []
    if (page.htmlAst) {
        page.htmlAst.children.forEach(function(htmlElement) {
            if (htmlElement.children) {
                content.push(<p className="mb-4">{htmlElement.children[0].value}</p>)
            }
        })
    }

    return (
        <div>

        <SEO title={page.frontmatter.title} description={page.frontmatter.blurb} />
        <div id="colorlib-page">
            <Navbar />

            {/* main page contents */}
            <div id="colorlib-main">
                <BackgroundDiv className="hero-wrap" style={{height:"100vh", position:"fixed"}}>
                    <div className="overlay"></div>
                </BackgroundDiv>
                <div className="d-flex flex-column justify-content-center" style={{minHeight:"100vh"}}>
                    <div className="d-flex justify-content-center align-items-center" style={{paddingTop:"40px", paddingBottom:"40px"}}>
                        <div className="col-md-8 text text-center">
                            <div className="desc">
                                <h1 className="mb-4" style={{fontWeight:900}}>{page.frontmatter.title}</h1>
                                <p className="mb-4">
                                    {page.frontmatter.blurb}
                                </p>
                                <div style={{paddingBottom: "10px"}}>
                                    {output}
                                    {content}
                                </div>
                                <div className="blog-entry ftco-animate mb-3">
                                    {outputImage}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* end of #colorlib-main */}
        </div>
        {/* end of #colorlib-page */}

        </div>
    )
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            frontmatter {
                pageImage {
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    publicURL
                }
                title
                topic
                blurb
                document
                documentType
            }
            html
            htmlAst
        }
    }
`